import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide3/image/imgPortadaGuia3.png"
import img1 from "@components/pageGuide/guides/guide3/image/img3.png"
import slider1 from "@components/pageGuide/guides/guide3/image/slider1.png"
import slider2 from "@components/pageGuide/guides/guide3/image/slider2.png"
import slider3 from "@components/pageGuide/guides/guide3/image/slider3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Guía para mejorar el proceso de calidad en el servicio al cliente
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Alrededor del 79% de los consumidores prefieren a una marca que demuestre
      que realmente se preocupa por ellos. Por eso, es importante generar
      experiencias únicas en los clientes ya que es un elemento clave para que
      las personas confíen en tu marca y te compren. ¿Quieres aprender a
      lograrlo? Aquí lo harás en 7 pasos.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    La experiencia del cliente tiene un impacto cada vez mayor en la
    satisfacción, dado que está destinada a superar el precio y el producto como
    el principal diferenciador, lo que significa que es mejor comenzar a
    centrarse en ofrecer experiencias increíbles a sus clientes.
    <br /> <br />
    Está demostrado que las personas realmente valoran un buen servicio al
    cliente. Si tu servicio aporta un valor adicional de forma regular, es
    probable que resulte en mejores ventas a largo plazo. Por eso, aquí
    aprenderás paso a paso a mejorar este proceso.
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al cliente",
    title: title,
    description: textDescription,
    classtext: "guide__description3",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Cómo enviar mensajes y respuestas personalizadas.",
      },
      {
        key: 2,
        text: "Por qué ofrecer un soporte omnicanal.",
      },
      {
        key: 3,
        text: "Tips para respuestas automáticas no genéricas.",
      },
      {
        key: 4,
        text: "Cómo proporcionar opciones de autoservicio.",
      },
      {
        key: 5,
        text: "Pasos para ofrecer una atención al cliente las 24/7.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
